.expenses {
  padding: 1rem;
  background-color: rgb(31, 31, 31);
  margin: 2rem auto;
  width: 50rem;
  max-width: 95%;
}

.expenses-list__fallback {
  color: white;
  text-align: center;
}